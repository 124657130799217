.border-rad-none .MuiOutlinedInput-root{
 border-radius: 4px 0 0 4px;
}
.loader {
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 display: flex;
 align-items: center;
 justify-content: center;
}
.MuiPaper-root-MuiDialog-paper{
 max-width: 700;
}

.loader:before {
 content: '';
 position: absolute;
 background: #000;
 opacity: .2;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 z-index: 1;
}
header{
 background: #fff!important;
}
.loginBG{
 position: relative;
}
.loginBG::after{
 content: '';
 position: absolute;
 left: 0;
 height: 100%;
 width: 50%;
 background-color: #fff;
 top: 0;
 z-index: 0;
}
.loginBG::before{
 content: '';
 position: absolute;
 right: 0;
 height: 100%;
 width: 50%;
 background-color: #270659;
 top: 0;
 z-index: 0
}
.MuiPaper-root-MuiCard-root{
 box-shadow: 'none',
}
.MuiTabs-flexContainer{
 justify-content: center;
 align-items: center;
}

.whiteBG{
 background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 1px 2px 5px #cbcbcb;
}
.MuiTab-textColorPrimary.tabtext {
 margin: 0 5px;
 background: #dfdfdf!important;
 border-radius: 5px;
 border-bottom-right-radius: 0;
 border-bottom-left-radius: 0;
 color: #777!important;
}
.tabtext.Mui-selected {
 margin: 0 5px;
 background: #ffffff!important;
 color: #000000!important;
}
.tabdesign .MuiTabs-indicator{
 display: none!important;
}


@media (max-width: 600px) {
.MuiButton-containedPrimary.newsample-button{
 position: fixed;
 bottom: 20px;
 right: 20px;
 height: 50px;
 width: 50px;
 min-width: auto;
 border-radius: 50%;
 overflow: hidden;
 z-index: 2;
 padding: 0;
} 
.MuiButton-containedPrimary .text-hide{
 display: none;
}
.MuiButton-containedPrimary.newsample-button .MuiButton-startIcon{
 margin:0
}
}